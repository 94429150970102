div#menu-Container {
  position: absolute;
  right: 0;
  width: 400px;
  height: calc(100% + 1px);
  border-left: solid 2px #fff3;
  transition: .5s;
  transition-delay: .025s;
  z-index: 999;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spinning-Loading-Bar::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 90px;
  width: 90px;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
  top: 40%;
}


#page-Content.sidebar-Hidden div#menu-Container {
  right: -400px;
}

.menu-Title-Style {
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding: 0.3rem;
  background: var(--Brand-Colour-Secondary);
}

.menu-Title-Style>img {
  width: 32px;
  margin-left: 0.5rem;
}

.menu-Logo-Style {
  margin-left: 0rem;
  padding: 0;
  padding-top: 0.1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
}

.menu-Button-Style {
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  --border-color: var(--Brand-Colour-White);
  --border-style: solid;
  --border-width: 2px;
}

.ion-page {
  background-color: var(--Brand-Colour-Primary);
}

.menu-content {
  height: calc(100% - 55px);
  --background: var(--Brand-Colour-Primary);
}

.block-Card-Style {
  position: relative;
  display: flex;
  margin: 0.8rem;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 25px;
  background-color: var(--Brand-Colour-Secondary);
  border: solid #fff4 3px;
  box-shadow: 0 0 10px 0px #2a2a2a;
}

.block-Card-Content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  text-align: left;
}

.block-Card-Content ul {
  margin: 0;
  padding-left: 30px;
}

.letter-Card-Style {
  padding: 2rem 0;
  margin: 0 1rem;
  background-color: var(--Brand-Colour-Primary-85);
  /* border: 2px solid rgb(255, 255, 255) !important; */
  border-radius: 25px;
}

.block-Letter-Text {
  text-align: center !important;
  font-size: 60px;
  color: var(--Brand-Colour-White);
  font-weight: bold;
  font-family: 'SSENBold';
}

.block-Letter-Loading {
  text-align: center !important;
  height: 110px;
  font-size: 60px;
  color: var(--Brand-Colour-White);
  font-weight: bold;
  font-family: 'SSENBold';
}

.block-Card-Title {
  text-align: left;
  font-size: 1.5rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  gap: 0.2rem;
  padding: 0 1rem;
}

.explanation-Card-Style {
  background-color: var(--Brand-Colour-Secondary);
  box-shadow: 0px 0px 10px -1px #181818;
  border-radius: 25px;
  padding: 1rem;
}

.explanation-Card-Title {
  text-align: left;
  font-size: 1.47rem;
  gap: 0.3rem;
  padding: 0 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
}

.explanation-Card-Content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  text-align: left;
}

.information-Card-Style {
  background-color: #5b8e9f;
  box-shadow: 0px 0px 10px -1px #181818;
  border-radius: 25px;
  padding: 1rem;
}

.information-Card-Title {
  text-align: left;
  font-size: 1.5rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENBold';
  gap: 0.3rem;
  padding: 0 1rem;
}

.information-Card-Content {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: var(--Brand-Colour-White);
  font-family: 'SSENRegular';
  text-align: left;
}

.information-Button-Style {
  height: auto;
  width: 100%;
  --background: var(--Brand-Colour-Primary-85);
  --border-style: solid;
  --border-color: var(--Brand-Colour-White);
  --border-radius: 5rem;
  --border-width: 2px;
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  font-family: 'SSENBold';
}

.menu-Toggle {
  position: absolute;
  top: calc(50% - 30px);
  margin-left: -25px;
  width: 25px;
  height: 60px;
  background: var(--Brand-Colour-Primary);
  border: none;
  border-left: solid 2px #fff4;
  border-top: solid 2px #fff4;
  border-bottom: solid 2px #fff4;
  border-radius: 15px 0 0 15px;
  color: var(--Brand-Colour-White);
  transition: 0.1s;
}

.menu-Toggle:hover {
  background: var(--Brand-Colour-Primary-85);
  cursor: pointer;
}

.menu-Toggle ion-icon {
  position: relative;
  width: 1.2rem;
  height: 100%;
  margin-left: 0.2rem;
  transition: 0.25s;
}

.menu-Toggle ion-icon.rotate180deg {
  transform: rotate(180deg);
}

@media only screen and (max-width: 640px) {

  div#menu-Container {
    width: 100vw;
    border-left: 0;
  }

  #page-Content.sidebar-Hidden div#menu-Container {
    right: -100vw;
  }

}