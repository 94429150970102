.heading-Toolbar-Style {
  --background: #ffffff;
  --border-color: var(--Brand-Colour-Primary);
  --border-width: 8px 0;
}

.headingRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
}

.headingRow img {
  height: 32px;
}