div#mapbox-Container {
    position: absolute;
    left: 0;
    width: calc(100% - 400px);
    height: 100%;
    transition: width .5s;
    transition-delay: .025s;
}

#page-Content.sidebar-Hidden div#mapbox-Container {
    width: 100%;
    transition: width 0s;
}

.mapboxgl-ctrl-geocoder {
    position: fixed !important;
    top: 80px !important;
    left: 20px !important;
    border-radius: 20px !important;
    width: 230px !important;
    min-width: 230px !important;
}

.mapboxgl-ctrl-geocoder .suggestions {
    border-radius: 20px !important;
}

#map-Buttons {
    position: absolute;
    bottom: 32px;
    right: 410px;
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 99;
    pointer-events: none;
    transition: .5s;
    transition-delay: .025s;
}

.sidebar-Hidden #map-Buttons {
    right: 10px;
}

#map-Buttons .map-Button-Container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: end;
    gap: 0.8rem;
}

#map-Buttons .map-Button-Label {
    background-color: var(--Brand-Colour-Secondary);
    padding: 0.4rem 0.7rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 10px 0px #1818189e;
    font-size: 0.9rem;
    opacity: 0;
    transition: opacity 0.5s;
    white-space: nowrap;
}

@media (pointer: fine) {
    #map-Buttons ion-button:hover~.map-Button-Label {
        opacity: 1;
    }
}


#map-Buttons ion-button {
    min-width: 45px;
    min-height: 45px;
    --padding-end: 0;
    --padding-start: 0;
    --background: var(--Brand-Colour-Secondary);
    pointer-events: all;
    flex: 1;
}

#map-Buttons .map-Button-Container:nth-child(2) ion-button {
    --background: var(--Brand-Colour-Yellow);
}

#map-Buttons .map-Button-Container:nth-child(3) ion-button {
    --background: var(--Brand-Colour-Violet);
}

#map-Buttons .map-Button-Container:nth-child(4) ion-button {
    --background: var(--Brand-Colour-Green);
}



@media only screen and (max-width: 640px) {

    div#mapbox-Container {
        width: 100%;
        transition: none;
    }

    .mapboxgl-ctrl-geocoder {
        width: calc(100vw - 40px) !important;
    }

    #map-Buttons {
        right: calc(100vw + 10px);
    }

}
