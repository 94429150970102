/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #00365f;
	--ion-color-primary-rgb: 0,54,95;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #003054;
	--ion-color-primary-tint: #1a4a6f;

	--ion-color-secondary: #64a1bb;
	--ion-color-secondary-rgb: 100,161,187;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #588ea5;
	--ion-color-secondary-tint: #74aac2;

	--ion-color-tertiary: #273c4e;
	--ion-color-tertiary-rgb: 39,60,78;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #223545;
	--ion-color-tertiary-tint: #3d5060;

	--ion-color-success: #7db559;
	--ion-color-success-rgb: 125,181,89;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #6e9f4e;
	--ion-color-success-tint: #8abc6a;

	--ion-color-warning: #fbb800;
	--ion-color-warning-rgb: 251,184,0;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #dda200;
	--ion-color-warning-tint: #fbbf1a;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;

}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #00365f;
    --ion-color-primary-rgb: 0,54,95;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #003054;
    --ion-color-primary-tint: #1a4a6f;
  
    --ion-color-secondary: #64a1bb;
    --ion-color-secondary-rgb: 100,161,187;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #588ea5;
    --ion-color-secondary-tint: #74aac2;
  
    --ion-color-tertiary: #273c4e;
    --ion-color-tertiary-rgb: 39,60,78;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #223545;
    --ion-color-tertiary-tint: #3d5060;
  
    --ion-color-success: #7db559;
    --ion-color-success-rgb: 125,181,89;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #6e9f4e;
    --ion-color-success-tint: #8abc6a;
  
    --ion-color-warning: #fbb800;
    --ion-color-warning-rgb: 251,184,0;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #dda200;
    --ion-color-warning-tint: #fbbf1a;
  
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
  
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146,148,156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
  
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255,255,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

:root {
  --Brand-Colour-Primary: #00365f;
  --Brand-Colour-Secondary: #64a1bb;
  --Brand-Colour-Tertiary: #273c4e;
  --Brand-Colour-White: #ffffff;
  --Brand-Colour-Black: #000000;
  --Brand-Colour-Yellow: #fbb800;
  --Brand-Colour-Green: #7db559;
  --Brand-Colour-Violet: #b079b3;

  --Brand-Colour-Primary-85: #1a4972;
  --Brand-Colour-Primary-70: #406189;
  --Brand-Colour-Primary-55: #667c9f;
  --Brand-Colour-Primary-40: #8d9cb8;
  --Brand-Colour-Primary-25: #b6bed2;
  --Brand-Colour-Primary-10: #e0e4ed;
}

@font-face {
  font-family: 'SSENRegular';
  src: url('/public/assets/fonts/MuseoSans_300.otf');
}

@font-face {
  font-family: 'SSENBold';
  src: url('/public/assets/fonts/MuseoSans_700.otf');
}